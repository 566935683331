import '../scss/qsearchcustom_front.scss';
"use-strict"
let jQuery = require('jquery');

window.J    = jQuery;
window.Jd   = J(document);

Jd.ready(function(){

    if (J('#qsearch_custom_form').length) {

        J('#qsearch_custom_loader').addClass('qsearch-custom-loader-visible');

        var autoscroll = false;

        if (J('input[name="current_form_search"]').val().length) {

            if (J('li.nav-item[data-id="citation-database"]').length) {
                J('li.nav-item[data-id="citation-database"]').children('a')[0].click();
            }

            if (J('input[name="reagent_cell"]').length && J('input[name="reagent_molecule"]').length) {

                if (J('input[name="reagent_cell"]').val().length > 0 || J('input[name="reagent_molecule"]').val().length > 0) {
                    autoscroll = true;
                }
            }

            if (J('input[name="citation_cell"]').length && J('input[name="citation_molecule"]').length && J('input[name="citation_product"]').length) {

                if (J('input[name="citation_cell"]').val().length > 0 || J('input[name="citation_molecule"]').val().length > 0 || J('input[name="citation_product"]').val().length > 0) {
                    autoscroll = true;
                }
            }

            if (autoscroll) {

                if (J('body').attr('id') != 'product') {
                    setTimeout(function() { J("html,body").stop().animate({scrollTop: (J('#qsearch_custom_form').offset().top) - 120}, 10); }, 500);
                }
            }

            setTimeout(function(){ J('#qsearch_custom_loader').removeClass('qsearch-custom-loader-visible'); }, 1000);

        } else {
            J('#qsearch_custom_loader').removeClass('qsearch-custom-loader-visible');
        }

        // >> -- CELL FINDER --
        Jd.off('focusin', '#cell').on('focusin', '#cell', function () {
            QautocompleteFocus('cell_autocompletion_result', 'in');
        });
        Jd.off('focusout', '#cell').on('focusout', '#cell', function(){
            QautocompleteFocus('cell_autocompletion_result', 'out');
        });
        Jd.off('keydown keyup', '#cell').on('keydown keyup', '#cell', function(){
            let value = J(this).val();value = value.toUpperCase();
            Qautocomplete('cell_autocompletion_result', value);
        });
        Jd.off('click', '.cell-autocompletion-item').on('click', '.cell-autocompletion-item', function(){
            let value = J(this).attr('data-value');
            QautocompleteClick('cell', 'cell_autocompletion_result', value);
        });
        // <<

        // >> -- REAGENT FINDER CELL --
        Jd.off('focusin', '#cell-type').on('focusin', '#cell-type', function () {
            QautocompleteFocus('reagent_cell_autocompletion_result', 'in');
        });
        Jd.off('focusout', '#cell-type').on('focusout', '#cell-type', function(){
            QautocompleteFocus('reagent_cell_autocompletion_result', 'out');
        });
        Jd.off('keydown keyup', '#cell-type').on('keydown keyup', '#cell-type', function(){
            let value = J(this).val();value = value.toUpperCase();
            Qautocomplete('reagent_cell_autocompletion_result', value);
        });
        Jd.off('click', '.reagent-cell-autocompletion-item').on('click', '.reagent-cell-autocompletion-item', function(){
            let value = J(this).attr('data-value');
            QautocompleteClick('cell-type', 'reagent_cell_autocompletion_result', value);
        });
        // <<

        // >> -- REAGENT FINDER MOLECULE --
        Jd.off('focusin', '#biomolecule').on('focusin', '#biomolecule', function () {
            QautocompleteFocus('reagent_molecule_autocompletion_result', 'in');
        });
        Jd.off('focusout', '#biomolecule').on('focusout', '#biomolecule', function(){
            QautocompleteFocus('reagent_molecule_autocompletion_result', 'out');
        });
        Jd.off('keydown keyup', '#biomolecule').on('keydown keyup', '#biomolecule', function(){
            let value = J(this).val();value = value.toUpperCase();
            Qautocomplete('reagent_molecule_autocompletion_result', value);
        });
        Jd.off('click', '.reagent-molecule-autocompletion-item').on('click', '.reagent-molecule-autocompletion-item', function(){
            let value = J(this).attr('data-value');
            QautocompleteClick('biomolecule', 'reagent_molecule_autocompletion_result', value);
        });
        // <<


        // >> -- CITATION DATABASE --
        Jd.off('focusin', '#molecule-vectors').on('focusin', '#molecule-vectors', function () {
            QautocompleteFocus('citation_molecule_autocompletion_result', 'in');
        });
        Jd.off('focusout', '#molecule-vectors').on('focusout', '#molecule-vectors', function(){
            QautocompleteFocus('citation_molecule_autocompletion_result', 'out');
        });
        Jd.off('keydown keyup', '#molecule-vectors').on('keydown keyup', '#molecule-vectors', function(){
            let value = J(this).val();value = value.toUpperCase();
            Qautocomplete('citation_molecule_autocompletion_result', value);
        });

        J("form#citation_database_form").submit(function (event) {

            /*if (J('body').attr('id') != 'product') {
                $('html,body').animate({
                    scrollTop: $('.qsearch-custom-citation-result').offset().top - 60
                }, 400);
            }*/
        });
        // <<

        Jd.off('click', '.citation-molecule-autocompletion-item').on('click', '.citation-molecule-autocompletion-item', function () {
            let value = J(this).attr('data-value');
            QautocompleteClick('molecule-vectors', 'citation_molecule_autocompletion_result', value);
        });

        Jd.off('focusin', '#all_products').on('focusin', '#all_products', function(){
            QautocompleteFocus('citation_product_autocompletion_result', 'in');
        });

        Jd.off('focusout', '#all_products').on('focusout', '#all_products', function () {
            QautocompleteFocus('citation_product_autocompletion_result', 'out');
        });

        Jd.off('keydown keyup', '#all_products').on('keydown keyup', '#all_products', function () {
            let value = J(this).val();
            value = value.toUpperCase();
            Qautocomplete('citation_product_autocompletion_result', value);
        });

        Jd.off('click', '.citation-product-autocompletion-item, .citation-product-active').on('click', '.citation-product-autocompletion-item, .citation-product-active', function () {
            let value = J(this).attr('data-value');
            QautocompleteClick('all_products', 'citation_product_autocompletion_result', value);
        });

        if (J('.citation-product-active').length) {
            J('.citation-product-active').trigger('click');
        }


        Jd.off('click', 'a[href="#search-testimonials-database"]').on('click', 'a[href="#search-testimonials-database"]', function(e){

            e.preventDefault();

            J('input[name="citation_cell"]').val('MEF');
            J('input[name="citation_molecule"]').val('Retrovirus/Lentivirus');
            J('input[name="citation_product"]').val('LentiBlast Premium Transduction Enhancer');

            J("form#citation_database_form").submit();
            J('#qsearch_custom_loader').addClass('qsearch-custom-loader-visible');
            return false;
        });
    }

    function QautocompleteFocus(id, type='in') {
        if (type === 'in') {
            J('#'+id).show();
            J('#'+id).children('div').show();
            return
        }
        if (type === 'out') {
            setTimeout(function(){
                J('#'+id).hide();
                J('#'+id).children('div').hide();
                return
            },300);
        }
    }

    function QautocompleteClick(inputId, divId, value) {
        J('#'+inputId).val(value);
        J('#'+divId).hide();
        J('#'+divId).children('div').hide();
        return;
    }

    function Qautocomplete(id, value) {
        J('#'+id).hide();
        J('#'+id).children('div').hide();
        if (value.length >= 1) {
            J('#'+id).show();
            J('#'+id).children('div').each(function(index, item){
                let text = J(item).text();
                text = text.toUpperCase();
                if (text.indexOf(value) != -1) J(item).show();
            });
        }
    }


    $(document).off('click', '#producttabs > .tabs > ul > .nav-item').on('click', '#producttabs > .tabs > ul > .nav-item', function(e){

        var text = $(this).children('a').text();

        if (text === 'Citation database') {
            $('.qsearch-custom-citation-result').show();
        } else {
            $('.qsearch-custom-citation-result').hide();
        }
    });
});


